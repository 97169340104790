.table-like {
    display: table;
    width: 50%;
    margin-left: 50px;
}

.table-row {
    display: table-row;
}

.table-cell-fix-L {
    width: 500px;
}

.table-cell-fix-M {
    width: 250px;
}

.table-cell,
.table-cell-fix-L,
.table-cell-fix-M {
    display: table-cell;
    padding: 10px;
    border: 1px solid #ddd;
}

.header .table-cell {
    background-color: #f8f8f8;
    font-weight: bold;
}

.select-column {
    display: table-cell;
    width: 25px; 
    padding: 10px 5px; 
    border: 1px solid #ddd;
    text-align: center; 
}