.button-link {
    display: inline-block;
    text-decoration: none;
}

.button-link:hover,
.button:hover {
    background-color: #0056b3;
}

button,
.button-link {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    margin: 2px;
    transition: background-color 0.3s;
    cursor: pointer;
}